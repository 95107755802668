<template>
    <CContainer fluid>
        <CRow>
            <CCol class="col-12">
                <CCard>
                    <CCardHeader>
                        <strong>Öğrencilere E-posta Gönder</strong>
                    </CCardHeader>
                    <CCardBody>
                        <CRow>
                            <CCol v-if='!isDataLoading'>
                                <label>Şablon Seçin</label>
                                <Select2 v-model="templateId" :options="templatelist" @change="myChangeEvent($event)"
                                    @select="mySelectEvent($event)" />
                            </CCol>
                            <CCol v-if='!isDataLoading'>
                                <label>Öğrencileri Seçin</label>
                                <template>
                                    <CMultiSelect :options="options2" clearSearchOnSelect :search='true'
                                        selectionType="tags" @update='(e) => slectedStudentList = e'
                                        :selected='slectedStudentList' />
                                </template>
                            </CCol>
                            <CCol v-if='!isDataLoadingTeacher'>
                                <label>Öğretmen Seç</label>
                                <template>
                                    <CMultiSelect :options="TeacherOptions" clearSearchOnSelect :search='true'
                                        selectionType="tags" @update='(e) => slectedTeacherList = e'
                                        :selected='slectedTeacherList' />
                                </template>
                            </CCol>
                        </CRow>
                        <CRow>                            
                            <CCol class="col-2">
                                <CButton @click="sendEmailToStudent" block color="success" class="mr-28">Eposta gönder
                                </CButton>
                            </CCol>
                        </CRow>
                    </CCardBody>
                </CCard>
            </CCol>
        </CRow>
    </CContainer>
</template>

<script>
import { CMultiSelect } from '@coreui/vue-pro'
import MaskedInput from 'vue-text-mask'
import { studentsCredit } from '@/services/api-service'
import { StudentAdministration, TeacherAdministration, SendGridEmailTemplates } from '@/services/api-service'
import VueLoadingButton from 'vue-loading-button'
import { v4 as uuidv4 } from 'uuid';
import router from "@/router";
import Select2 from 'vue3-select2-component';

export default {
    name: 'SendEmail',
    components: {
        CMultiSelect,
        VueLoadingButton,
        MaskedInput,
        Select2
    },
    mounted() {
        this.loadTemplateData()
        this.loadStudents()
        this.loadTeachersList()
    },
    watch: {
    },
    data() {
        return {

            TeacherOptions: [],
            options2: [],
            templateId: null,
            templatelist: [],
            name: '',
            studentList: [],
            isDataLoading: false,
            isDataLoadingTeacher: false,
            slectedStudentList: [],
            slectedTeacherList: [],
        }
    },
    computed: {
    },
    methods: {
        myChangeEvent(val) {
            console.log(val);
        },
        mySelectEvent({ id, text }) {
            this.templateId = id;
        },
        async loadTemplateData() {
            this.isListLoading = true;
            this.templatelist = [];
            const result = await SendGridEmailTemplates.getDropDownEmailTemplates()
            result.data.forEach(e => {
                let data = {
                    id: e.id,
                    text: e.name
                }
                this.templatelist.push(data);
            });
            this.isListLoading = false;
        },
        async loadStudents() {
            this.isDataLoading = true
            this.studentList = [];

            const result = await studentsCredit.getDropDownStudents()
            this.studentList = result.data
            this.studentList.forEach(e => { 
                let data = {
                    value: e.id,
                    text: e.value,
                    selected: false
                }
                this.options2.push(data);
            });
            this.isDataLoading = false
        },        
        async loadTeachersList() {
            this.isDataLoadingTeacher = true
            const result = await TeacherAdministration.teachersDropdown()
            console.log("teacher List", result);
            if (result.data) {
                result.data.forEach(e => {
                let data = {
                    value: e.value,
                    text: e.label,
                    selected: false
                }
                this.TeacherOptions.push(data);
            });
            this.isDataLoadingTeacher = false
            }
        },
        async sendEmailToStudent() {
            this.isDataLoading = true;
            try {
                console.log(this.slectedTeacherList)
                if (this.templateId && (this.slectedStudentList.length > 0 || this.slectedTeacherList.length > 0)) {
                    let data = {
                        sendGridEmailTemplateId: this.templateId,
                        studentList: this.slectedStudentList,
                        teacherList: this.slectedTeacherList
                    }
                    const result = await SendGridEmailTemplates.sendEmails(data)
                    this.$toast.success("E-posta başarıyla gönderildi!")
                    this.slectedStudentList = []
                    this.slectedTeacherList = []
                    this.templateId = null
                } else {
                    this.$toast.error("Lütfen geçerli verileri girin")
                }
                this.isDataLoading = false;
            } catch (e) {
                this.$toast.warning("Lütfen geçerli verileri girin")
            }
            this.isDataLoading = false;
        },
    },

}
</script>
